import { IAppState, IPage, VevDispatch } from 'vev';
import { extendAppState } from '../core/state';
import { isBrowser } from './dom';

const DOUBLE_SLASH = /\/{2,}/g;
const TRAILING_SLASH = /\/$/;
const LEADING_SLASH = /^\//;

const loadedPages: { [pageKey: string]: boolean } = {};
const pageFetchPromises: { [url: string]: Promise<PageLoad> } = {};
const headers: { [path: string]: PageHeader } = {};
// Save away headers for current page

if (isBrowser) savePageHead();

type PageHeader = {
  title: string;
  meta: NodeListOf<HTMLMetaElement>;
};

type PageLoad = {
  /** Pre-rendered page contents (vevroot innerHTML) */
  html: string;
  /** State for the given page */
  state: IAppState;
  /** Dependencies for the give page, vev.js is excluded */
  scripts: string[];
  /** This, style tag, is already added to dom */
  style?: HTMLStyleElement;
};

export function setLoadedPage(pageKey: string) {
  loadedPages[pageKey] = true;
}

const addStyleTag = (styleTag: HTMLStyleElement, root?: HTMLElement | null) => {
  if (root) {
    document.body.appendChild(styleTag);
    root.insertAdjacentElement('afterend', styleTag.cloneNode(true) as Element);
  } else {
    document.body.appendChild(styleTag);
  }
};

export function getBaseDir(fromPath: string, pageKey: string, pages: IPage[]): string {
  const page = pages.find((p) => p.key === pageKey);

  // Should not be cleared anywhere now, but just in case
  if (page?.is404) {
    page.path = '404';
  }

  // Replace index.html with empty string if added (ZIP option)
  const path = (page?.path || '').replace('/index.html', '');
  const dir = fromPath.replace(
    new RegExp(`(${path}|${encodeURIComponent(path)})` + '/?(index.html)?$'),
    '',
  );

  return dir.replace(/(^\/|\/$)/g, '');
}

export function pageKeyByPath(path = '', pages: IPage[], dir?: string): string | undefined {
  path = path.replace(new RegExp(`^\\/?${cleanPath(dir || '')}?`), '');
  path = removeUnnecessarySlashes(cleanPath(path));

  const indexPage = pages.find((page) => page.index) || pages[0];
  if (!path || path === cleanPath(dir || '')) return indexPage.key;

  for (const page of pages) {
    if (path.replace(/^\//, '') === page.key) return page.key;
    // Finding start index in path for the page path (may be none (-1)), also checking if path is sub string of path
    const startIndex = path.indexOf(page.path);
    const pagePath = removeUnnecessarySlashes(page.path || '');
    // If page path is substring
    // then we check if they're a perfect match  extracting substring of the path
    if (startIndex !== -1 && path === pagePath) {
      return page.key;
    }
    if (removeLeadingAndTrailingSlash(page.path) === removeLeadingAndTrailingSlash(path)) {
      return page.key;
    }
  }
}

const removeUnnecessarySlashes = (path: string) => path.replace(/(^\/)|(\/$)/g, '');

const removeLeadingAndTrailingSlash = (str: string): string => str.replace(/^\/|\/$/g, '');

export const join = (...path: string[]) => {
  let res = '/' + path[0].replace(LEADING_SLASH, '').replace(TRAILING_SLASH, '');
  for (let i = 1; i < path.length; i++) {
    res += '/' + path[i].replace(LEADING_SLASH, '').replace(TRAILING_SLASH, '');
  }
  res = res.replace(TRAILING_SLASH, '');
  if (!res.endsWith('.html')) res += '/';
  return res;
  // return (path.join('/') + '/').replace(DOUBLE_SLASH, '/');
};

export function cleanPath(path: string | undefined) {
  const p = !path
    ? ''
    : path
        // remove starting .
        .replace(/^\./, '')
        // Remove query >azløkjbhvgcx>Zogfd8 or hash
        .replace(/(\?|#).*$/, '')
        // Remove double slash
        .replace(DOUBLE_SLASH, '/')
        // Remove trailing slash (but not if it's starting slash (path = "/"))
        .replace(/(?!^\/)\/$/, '');

  return /\.\w+$/.test(p) || p.endsWith('/') ? p : p + '/';
}

/**
 *  Get page path by key
 */
export function pagePathByKey(pageKey: string, pages: IPage[], dir?: string): string {
  const page = pages.find((page) => page.key === pageKey) || get404Page(pages);
  return page ? cleanPath(join('/', cleanPath(dir), page.path || '')) : '';
}

/**
 * Get 404 page if it exists
 */
export function get404Page(pages: IPage[]) {
  return pages.find((page) => page.is404);
}

export async function loadPage(
  pageKey: string,
  state: IAppState,
  dispatch: VevDispatch,
): Promise<void> {
  if (!pageKey) return;

  if (state.editor) return dispatch('load-page', pageKey);
  if (loadedPages[pageKey] || state.models.find((s) => s.key === pageKey)) return;
  loadedPages[pageKey] = true;

  let pageState: IAppState;

  if (state.pageApiUrl) {
    ({ state: pageState } = await fetchPageApi(
      `https://${state.pageApiUrl}/${state.project}/${pageKey}?type=json`,
      state.root,
    ));
  } else {
    const { host, dir } = state;
    let path = (host || dir || '').replace(/\/+$/, '') + pagePathByKey(pageKey, state.pages);
    if (!path.startsWith('/') && !state.resolveIndex) path = '/' + path;
    if (state.resolveIndex && !path.endsWith('.html')) path = path + 'index.html';

    let replacePaths: string | undefined = undefined;

    // If local embed we need to replace the assets paths using the host inferred in the local embed script
    if (state.replaceAssetsPaths) replacePaths = (state?.host || '') + 'assets/';
    // If local assets we need to convert asset paths to absolute paths, since state load might happen before page navigation
    if (state.localAssets && !state.replaceAssetsPaths && !state.customAssetPath) {
      const baseDir = !dir || dir === '/' ? '' : dir?.startsWith('/') ? dir : `/${dir}`;
      replacePaths = baseDir + '/assets/';
    }

    // If customAssetPath is set do not replace any paths
    if (state.customAssetPath) {
      if (state.replaceAssetsPaths)
        console.warn("Don't mix customAssetPath with replaceAssetsPaths!");
      replacePaths = undefined;
    }

    ({ state: pageState } = await fetchPage(
      path,
      replacePaths,
      state.root,
      path === state?.host + 'index.html',
    ));
  }

  // // Storing the head in page content (used in page component to replace head when page becomes active)
  // const page = pageState.models.find((p) => p.key === pageKey);
  // if (page) page.content = { head };

  if (pageState) extendAppState(pageState);
}

export async function fetchPage(
  path: string,
  replaceAssetsPath?: string,
  root?: HTMLElement | null,
  isIndexPage?: boolean,
): Promise<PageLoad> {
  // If page does not end with file extension or / then append /
  if (!/\.[a-z]+$/i.test(path) && !path.endsWith('/') && !path.endsWith('index.html')) path += '/';

  if (!pageFetchPromises[path]) {
    let pagePath = path;

    if (location.search) pagePath += location.search;

    pageFetchPromises[path] = fetch(pagePath).then(async (res) => {
      const html = await res.text();
      const el = document.createElement('div');

      el.innerHTML = html;
      const pageHtml = el.querySelector('vevroot');
      const style = el.querySelector('.vev-style') as HTMLStyleElement;
      const contentString = el.querySelector('script[type="text/vev"]')?.textContent;
      const scripts: string[] = [];
      const depEls = el.querySelectorAll('.vev-dep');
      // Store away the headers in
      savePageHead(path, el);
      // Check if headers need to be set
      if (path === location.pathname && document.title !== headers[cleanPath(path)]?.title) {
        updatePageHeader(path);
      }

      for (let i = 0; i < depEls.length; i++) {
        const node = depEls.item(i);
        const src = isScript(node) && node.getAttribute('src');
        if (src && !src.endsWith('/vev.js')) scripts.push(src);
      }

      const replacePaths = (str: string) => {
        if (isIndexPage) {
          return str.replaceAll('assets/', replaceAssetsPath || '');
        }
        const reg = /\.?\.?(\/\.\.)*\/?assets\//g;
        return str.replace(reg, replaceAssetsPath || '');
      };

      const state: IAppState | null = contentString
        ? JSON.parse(replaceAssetsPath ? replacePaths(contentString) : contentString)
        : null;

      if (style) {
        if (replaceAssetsPath) style.innerText = replacePaths(style.innerText);
        addStyleTag(style, root);
      }
      const projectId = state?.project;

      document.querySelectorAll(`.p${projectId}`).forEach((node) => {
        if (node.shadowRoot) node.shadowRoot.appendChild(style.cloneNode(true));
      });

      return {
        html: pageHtml?.innerHTML || '',
        state: state as IAppState,
        scripts,
        style,
      };
    });
  }

  return pageFetchPromises[path];
}

export async function fetchPageApi(path: string, root: HTMLElement | null): Promise<PageLoad> {
  if (!pageFetchPromises[path]) {
    pageFetchPromises[path] = fetch(path).then(async (res) => {
      const json = await res.json();
      savePageHeadTitle(path, json.title);

      const styleTag = document.createElement('style');

      if (json.css) {
        styleTag.innerText = json.css;
        document.body.appendChild(styleTag);
      }

      addStyleTag(styleTag, root);

      return {
        html: json.html,
        state: json.json,
        scripts: json.scripts,
        style: json.css,
      };
    });
  }

  return pageFetchPromises[path];
}

/** Stores the needed headers away in the header path map  */
function savePageHead(path: string = location.pathname, extractFrom: HTMLElement = document.head) {
  headers[cleanPath(path)] = {
    title: extractFrom.querySelector('title')?.innerText || '',
    meta: extractFrom.querySelectorAll('meta'),
  };
}

function savePageHeadTitle(path: string = location.pathname, title: string) {
  headers[cleanPath(path)] = {
    title,
    meta: document.head.querySelectorAll('meta'),
  };
}

/**
 * Apply headers for the given path
 * Looks for headers in the head path map object
 */
export function updatePageHeader(path: string = location.pathname) {
  const head = headers[cleanPath(path)];
  if (head) {
    document.title = head.title;
    // Query meta to remove
    const currentMeta = document.head.querySelectorAll('meta');
    // Insert new meta tags
    for (let i = 0; i < head.meta.length; i++) document.head.appendChild(head.meta.item(i));
    // Remove old meta tags
    for (let i = 0; i < currentMeta.length; i++) currentMeta.item(i).remove();
  }
}

function isScript(el: Element): el is HTMLScriptElement {
  return el.tagName === 'SCRIPT';
}
