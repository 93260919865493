import { IContent } from 'vev';

export function createKeyMap<T>(models: (T & { key: string })[]) {
  const keyMap: { [key: string]: T } = {};
  models.forEach((model) => {
    keyMap[model.key] = model;
  });
  return keyMap;
}

/** best performance in most browsers according to stackoverflow */
export function isObjectEmpty(obj: any) {
  if (!obj) return true;

  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

// Basically just spreads the overrides in order, but merges content and attrs
export function getModelOverrides(sortedOverrides: (Partial<IContent> | undefined)[]) {
  const res: Partial<IContent> = {};

  for (const overrides of sortedOverrides) {
    if (overrides && !isObjectEmpty(overrides)) {
      for (const key in overrides) {
        if (isNestedAttr(key)) {
          Object.assign(res, {
            [key]: {
              ...(res[key as keyof IContent] as { [key: string]: any }),
              ...(overrides[key as keyof IContent] as { [key: string]: any }),
            },
          });
        } else if (key === 'childContent') {
          const childContent: { [attr: string]: any } = {};
          for (const childKey in overrides.childContent) {
            childContent[childKey] = getModelOverrides([
              res.childContent?.[childKey] as { [key: string]: any },
              overrides.childContent[childKey] as { [key: string]: any },
            ]);
          }
          Object.assign(res, { childContent });
        } else {
          Object.assign(res, { [key]: overrides[key as keyof IContent] });
        }
      }
    }
  }
  return res;
}

function isNestedAttr(key: string) {
  return ['attrs', 'content'].includes(key);
}
