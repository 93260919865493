import React, { useContext, useEffect, useRef } from 'react';
import { IAppState, VevDispatch } from 'vev';
import { useContextModel } from '../context';
import { StateContext, getState } from '../state';
import { useGlobalStore } from './use-store';

export const useGlobalState = (key?: string, pkgKey?: string) => {
  const a = useRef<any>();
  const model = useContextModel();

  const globalStateKey = `${pkgKey || model?.type}__${key || model?.key}`;

  return useGlobalStore((store, dispatch) => {
    if (!a.current) a.current = (state: any) => dispatch('widget-state', state, globalStateKey);
    return [store?.widgetState?.[globalStateKey], a.current];
  });
};

/**
 * `useGlobalStateRef` returns a mutable ref to the global IAppState for the current context
 * Note that `useGlobalStateRef()` It will not trigger a render when app state changes,
 * but it can be used to improve the performance of components because you'll have access to the latest version of the state in all useEffects or useFrames
 * without needing to re-render your component
 */
export function useGlobalStateRef(): [React.MutableRefObject<IAppState>, VevDispatch] {
  const [listen, dispatch, uid] = useContext(StateContext);
  const state = useRef<IAppState>(getState(uid));

  useEffect(() => listen((s) => (state.current = s)), []);
  return [state, dispatch];
}
